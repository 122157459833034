





















import Vue from 'vue';
import AddCommissionAdvancedDetails from './AddCommissionAdvancedDetails.vue';
import AddCommissionBasicDetails from './AddCommissionBasicDetails.vue';
import AddCommissionCancel from './AddCommissionCancel.vue';
import AddCommissionRequestFormAdvancedDetails from './AddCommissionRequestFormAdvancedDetails.vue';
import AddDateFns from './AddDateFns.vue';
import AddUploadManager from './AddUploadManager.vue';
import ChangePrivacyV2 from './ChangePrivacyV2.vue';
import ChangeCommissionRequestForm from './ChangeCommissionRequestForm.vue';

export default Vue.extend({
  components: {
    AddCommissionAdvancedDetails,
    AddCommissionBasicDetails,
    AddCommissionCancel,
    AddCommissionRequestFormAdvancedDetails,
    AddDateFns,
    AddUploadManager,
    ChangeCommissionRequestForm,
    ChangePrivacyV2,
  },
  data() {
    return {
      v: '2020-12',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
